@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #1d1d1d;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #fff;
$icon-bg-color: rgba(255, 255, 255, 0.05);
 
$hover-size-icon-item-menu:13px;
$size-icon-item-menu:12px;
$hover-color-icon:#3e8ae6;
$hover-color-icon-background: #3e8ae610 ;
$icon-size: 27px;
$submenu-bg-color:#ffffff04;  
	 

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
} 

html{
  scroll-behavior: smooth; 
}
@font-face {
  font-family: "title2";
  -webkit-font-family:"title2" ;
  -moz-font-family:"title2";
  src: url('../assets/fonts/Vazir-Light.woff')  format('truetype')
}

.test{
  -webkit-transition:color .3s ease-in;
  transition:color .3s ease-in;
  -moz-transition:color .3s ease-in;
  -ms-transition:color .3s ease-in;
  -o-transition:color .3s ease-in;
}
@font-face {
  font-family: "IRANSans";
  -webkit-font-family:"IRANSans" ;
  -moz-font-family:"IRANSans";
  src: url('../assets/fonts/_iransans_medium.ttf')  format('truetype')
}

@font-face {
  font-family: "title3";
  -webkit-font-family:"title3" ;
  -moz-font-family:"title3";
  src: url('../assets/fonts/Vazir-Medium.woff')  format('truetype')
}

body {
  margin: 0;
   height: 100vh; 
  background-color: #2196f310;  
  z-index: -5;
 
}

.shapeDashBoard{
  position: absolute;
  z-index: -100;
  width: 100%;
  bottom: 0px;
  right: 0%;
  left: 0%;  
}

.MuiPagination-ul >li> button> svg{
  color:#000000
}

.layoutDashboard{
  border: 2px solid #007bff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.titleDashboard{
  color: rgb(231, 231, 231);
  min-height: 30px;
  text-align: start;
  border-bottom: 1px solid #888888;
  margin-left: 20px !important;
  margin-right: 15px !important;
  margin-bottom: 10px !important;
  font-family: "IRANSans";
  font-size: 16px; 
}
.not_show{
  display: none;
  visibility: hidden;
}
.shapeDashBoard{
  position: absolute;
  z-index: 1; 
  left: 0;
  right: 0%;
  bottom: 0%;
}
.process{
  border-radius: 20px;
  z-index:1;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-content: center;
  //filter: blur(3px);
  //bottom: -10%;
  //display: flex;
  //flex-wrap: wrap;
  position: absolute;
  //-webkit-filter: blur(3px);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
 
}
.layoutOptionDisable{
  background-color: #272727;
  color:#5a5a5a;
  font-size: 16px;
font-family: "title3"; 

}
.layoutOption{
  background-color: #141319;
  color:#5a5a5a;
  font-size: 16px;
font-family: "title3"; 

}
.layoutOption:hover{
  background: #007bff;
  font-family: "title3";
}
.fontNumber{
  font-family: "title3" !important;
}
.layout-selected-status{
  padding-left: 15px !important; 
}
.backgroundPostionProcess{
  position: absolute;
  z-index: 5;
 }
 .backgroundPostionProcess2{
  position: absolute;
  z-index: 5; 
 width: 100%;
 height: 50%; 
 display: flex;
 flex-grow: 0;
 justify-content: center;
 align-items: center;
 }
 .blurBackground{
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
.background_back{
  clip-path: polygon(100% 0,0 0,0 77.5%,1% 77.4%,2% 77.1%,3% 76.6%,4% 75.9%,5% 75.05%,6% 74.05%,7% 72.95%,8% 71.75%,9% 70.55%,10% 69.3%,11% 68.05%,12% 66.9%,13% 65.8%,14% 64.8%,15% 64%,16% 63.35%,17% 62.85%,18% 62.6%,19% 62.5%,20% 62.65%,21% 63%,22% 63.5%,23% 64.2%,24% 65.1%,25% 66.1%,26% 67.2%,27% 68.4%,28% 69.65%,29% 70.9%,30% 72.15%,31% 73.3%,32% 74.35%,33% 75.3%,34% 76.1%,35% 76.75%,36% 77.2%,37% 77.45%,38% 77.5%,39% 77.3%,40% 76.95%,41% 76.4%,42% 75.65%,43% 74.75%,44% 73.75%,45% 72.6%,46% 71.4%,47% 70.15%,48% 68.9%,49% 67.7%,50% 66.55%,51% 65.5%,52% 64.55%,53% 63.75%,54% 63.15%,55% 62.75%,56% 62.55%,57% 62.5%,58% 62.7%,59% 63.1%,60% 63.7%,61% 64.45%,62% 65.4%,63% 66.45%,64% 67.6%,65% 68.8%,66% 70.05%,67% 71.3%,68% 72.5%,69% 73.6%,70% 74.65%,71% 75.55%,72% 76.35%,73% 76.9%,74% 77.3%,75% 77.5%,76% 77.45%,77% 77.25%,78% 76.8%,79% 76.2%,80% 75.4%,81% 74.45%,82% 73.4%,83% 72.25%,84% 71.05%,85% 69.8%,86% 68.55%,87% 67.35%,88% 66.2%,89% 65.2%,90% 64.3%,91% 63.55%,92% 63%,93% 62.65%,94% 62.5%,95% 62.55%,96% 62.8%,97% 63.3%,98% 63.9%,99% 64.75%,100% 65.7%);
  background: linear-gradient(0deg, #00000022 0%, #00000001 100%);
 // background: #00000015;
  width: 100%;
  height: 60%;
  position: absolute;
  z-index: -1; 
 // top: 50%;
 // left: 50%;
  //background: red;
  // border: 18px solid #eee;
  // border-radius: 100%;
  // width: 12px;
  // height: 12px;
  // box-sizing: content-box;
}

// body:after {
//   content: '';
//   background: linear-gradient(130deg,#ff7a18,#af002d 41.07%,#319197 76.05%);
// }
// body:after, body:before {
//   display: block;
//   height: 600px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
// }
 
.icon_btn_deposit:hover{
  background-color: #007bff50 !important;
  outline: none !important;
  
}
.icon_btn_deposit:focus{
  outline: none !important;
  border: none !important;
}
.un-selected{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
 }
 .layoutInput{
  border-radius: 8px;
  //border-right: 1px solid #5098ef  ;
  //border: 1px solid #adadad;
  //margin-right: 10px;
  //margin-left: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  max-height: 43px;
  justify-content: center;
  align-content: center;
  background-color: #141319;
  min-height: 43px;
}

.layoutInputActive{
  border-radius: 8px;
  //border-right: 1px solid #5098ef  ;
  //border: 1px solid #adadad;
  //margin-right: 10px;
  //margin-left: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  max-height: 43px;
  justify-content: center;
  align-content: center;
  background-color: #232429;
  min-height: 43px;
  border-color: #007bff;
}

.background_title{
   
  
  background-color: #5098ef50; 
  border-right: 5px solid #5098ef  ;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; 
  padding-top: 3px;
  padding-bottom: 3px;
}
 .layoutInput:focus-within {

  //border-color: #66afe9;
 outline: 0; 
 box-shadow: inset 0 0px 1px#fff, 0 0 4px #fff5;


}
.MuiPaginationItem-outlinedSecondary.Mui-selected {
  color:#007bff !important;
  border-color: rgba(0, 123, 255, 0.5) !important;
  background-color: rgba(0, 123, 255, 0.12) !important;
}
.MuiToggleButton-root.Mui-selected{
  color:#007bff !important;
  border-color: rgba(0, 123, 255, 0.5) !important;
  background-color: rgba(0, 123, 255, 0.12) !important;
}
.MuiToggleButton-root{
  color:#676669 !important;
  border-color:#676669 !important;

}
.shadow{
         position: absolute;
         z-index: -1;
         box-shadow: 0 4px 4px   rgb(219, 219, 219)  ;
         border-radius: 8%;
         -webkit-border-radius: 8%;
         -moz-border-radius: 8%;
         -ms-border-radius: 8%;
         -o-border-radius: 8%;
         -webkit-border-radius: 50%;
         -moz-border-radius: 50%;
         -ms-border-radius: 50%;
         -o-border-radius: 50%;
         -webkit-animation: fadein 0.8s  alternate-reverse ; 
         animation: fadein 0.8s  alternate-reverse ;
         animation-iteration-count: infinite;
      //   background: inset 0 1px 1px rgb(206, 13, 13), 0 0 8px  rgba(230, 25, 25, 0.733);
         
}

.alert_text{  
  
  -webkit-animation: fadein 0.8s  alternate-reverse ; 
  animation: fadein 0.8s  alternate-reverse ;
  animation-iteration-count: infinite;
//   background: inset 0 1px 1px rgb(206, 13, 13), 0 0 8px  rgba(230, 25, 25, 0.733);
  
}
.pro-item-content{
      
  font-size: 14px;
  font-family: "IRANSans";
  font-weight: normal;
  
}

// .pro-icon-wrapper { 
//   width: 20px !important; 
//   max-width: 20px !important;
//   height: 40px !important;
//   line-height: 20px !important; 
// }
.dataFrom{

  background-color: transparent;
  outline: none;
  border: none;
  color:white;
  font-size: 16px;
  text-align: center;
  font-family: "title2";
  padding: 0%;
  margin: 0%;
  min-width: 135px !important;
  min-height: 45px !important; 
  text-align: center;
}
.dataFromFrist{

  background-color: transparent;
  outline: none;
  border: none;
  color:transparent;
  font-size: 16px;
  text-align: center;
  font-family: "title2";
  padding: 0%;
  margin: 0%;
  min-width: 135px !important;
  min-height: 45px !important; 
  text-align: center;
}
.dataFromActive{

  background-color: transparent;
  outline: none;
  border: none;
  color:white;
  font-size: 13px;
  text-align: center;
  font-family: "title2";
  padding: 0%;
  margin: 0%;
  max-width: 100px !important;
  min-height: 40px !important;
}


//********************************************************

.sc-kpOJdX{
  & label{
    font-size: 14px !important;
    color:#a4a4a4 !important;
    font-family: "title2" !important; 
    margin-left: 2px;
    margin-top: 7px !important; 
    width: 50%;
    padding-right: 4px;
  }
  // & div:not(.rdp__modal)  {
  //   border-radius: 5px;
  //   -webkit-border-radius: 5px;
  //   -moz-border-radius: 5px;
  //   -ms-border-radius: 5px;
  //   -o-border-radius: 5px;
  //   border: 1px solid #5a5a5a;
  //   max-width: 110px !important;
  //   height: 35px;
  //   display: flex;
  //   justify-content: center; 
  //   align-items: center;
     
  // }
  display: flex;
  justify-content: center; 
  align-items: center;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  
}
 
@keyframes fadein {
  0% {opacity:1;}
  100% {opacity:0.02;}
  }
.unitAmount{
  position: absolute;
  left: 2%;
  text-align: center;
  color: #a4a4a4; 
  top: 26%;
  font-size: 13px;
}

.dropzone{

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #282a36;
  border-style: dashed;
  background-color: transparent;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;

}
.dropzone:focus {
  border-color: #007bff;
}
.unitAmount2{
  position: absolute;
  left: 5%;
  text-align: center;
  color: #a4a4a4; 
  top: 30%;
  font-size: 14px;
}
.unitAmountIcon{
  position: absolute;
  left: 2%;
  text-align: center;
  color: #a4a4a4; 
  top: 15%;
}
.inputCard{
  background:transparent !important;
  font-family: 'IRANSans';  
  border: none;
  border-radius: 3px;
   
  text-align: right;
  width: 100%;
  height: 100%;
  color:white;
  padding-right: 5px;
  @include placeholder {
    color: #a4a4a4;
    font-size: 13px;
  }
} 

 
.inputCardRed{
  background:#d32f2f10 !important;
  font-family: 'IRANSans' !important;  
  border: none;
  border-radius: 3px;
  font-size: 16px; 
  text-align: right;
  width: 100%;
  height: 100%;
  color:white;
  padding-right: 5px;
  @include placeholder {
    color: #a4a4a4;
    font-size: 13px;
  }
} 
.inputCardActive{
  background:transparent !important;
  font-family: 'IRANSans';  
  border: none;
  border-radius: 3px;
  font-size: 16px; 
  text-align: right;
  width: 100%;
  height: 100%;
  color:#007bff;
  padding-right: 5px;
 direction: rtl;
 min-height: 40px !important;
  @include placeholder {
    color: #a4a4a4;
    font-size: 13px;
  }
} 
.inputCard3{
  background:transparent !important;
  font-family: 'IRANSans';  
  border: 1px solid #5a5a5a;
  border-radius: 3px;
  font-size: 14px; 
  text-align: right;
  width: 100%;
  height: 100%;
  color:white;
  padding-right: 5px;
 direction: rtl;
 min-height: 40px !important;
  @include placeholder {
    color: #a4a4a4;
    font-size: 15px;
  }
} 
.line_td{
  height: 40px !important;
  width: 60px !important;
  background-color: red;
}
.borderWhite{
 border-radius: 5px;
 -webkit-border-radius: 5px;
 -moz-border-radius: 5px;
 -ms-border-radius: 5px;
 -o-border-radius: 5px;
 border: 1px solid #5a5a5a;  
 min-height: 40px;
 @include placeholder {
  color: #a4a4a4;
  font-size: 14px;
}

 
}

.borderWhiteRed{
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid #d32f2f;
  font-family: 'title2' !important;  
  font-size: 12px !important;
  font-weight: 100 ;
  min-height: 35px;
  @include placeholder {
   color: #a4a4a4;
   font-size: 11px;
 }
 
  
 }


.borderWhiteActive{
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid #007bff;
  font-family: 'title2' !important;  
  font-size: 12px !important;
  font-weight: 100 ;
  min-height: 35px;
  @include placeholder {
   color: #a4a4a4;
   font-size: 11px;
 }
 }

.inputCard:focus {

  border-color: transparent;
 outline: 0;  

}
.borderWhiteActive:focus {

  border: 1px solid #5a5a5a;
  box-shadow: inset 0 1px 1px #007bff, 0 0 4px #007bff;
 outline: 0;  

}
.inputCard3:focus{
  border: 1px solid #5a5a5a;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 4px #5a5a5a;
 outline: 0; 
}
.borderWhite:focus {

  border: 1px solid #5a5a5a;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 4px #5a5a5a;
 outline: 0;  

}
.center_layout{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.icon_loading{
  color: white;
  -webkit-animation:spin 3s linear infinite;
  -moz-animation:spin 3s linear infinite;
  animation:spin 3s linear infinite;
}
 
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }}


@media (min-width: 600px){
  .MuiContainer-maxWidthSm   {
    max-width: 420px !important;
}
.shadow {
  width: 380px !important;
}
.dialog_card_system{
  min-width: 900px !important;
}

.statusWidth{
  min-width: 90px  ;
}
 
 
}
//  this is phone
@media (max-width: 600px){
 

  .inputCard{
    height: 45px !important; 
  }
  .makeStyles-root-7{
    margin-top: 40px !important;
  }
   
  .statusWidth{
    min-width: 100px !important;
  }
 .statusCard{
   min-width: 180px !important;
 }
 .fontSizeCellDiposit{
   font-size: 16px !important;
   
 }
 .fontSizeCellDiposit2{
  font-size: 14px !important;
}
 .dataFrom{
  min-height: 47px !important;
}
}

 












































.MenuItem{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}

#root ,#root > div {
  height: 100%;
}
 
.app {
 
  display: flex;
  position: relative;
  height: 100%;

  .btn-toggle {
    cursor: pointer;
    width: 55px;
    height: 55px;
    background: #353535;
    color: #fff;
    text-align: center;
     border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-top: 10px;
    margin-right: 10px; 
    font-size: 15px;
    display: none;
   position: absolute;
   z-index: 100;
  
   
  }
  .col-sm-6{
    top: 15px;
    bottom: 30px;
  }
  .bg-gradient-primary {
    background: #141319 !important;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.bg-gradient-danger {
  background: #d93737 !important;
  background: linear-gradient(45deg, #e55353 0%, #d93737 100%) !important;
  border-color: #d93737 !important;
}
.bg-gradient-info {
  background: #2982cc !important;
  background: linear-gradient(45deg, #39f 0%, #2982cc 100%) !important;
  border-color: #2982cc !important;
}
.bg-gradient-warning {
  background: #f6960b !important;
  background: linear-gradient(45deg, #f9b115 0%, #f6960b 100%) !important;
  border-color: #f6960b !important;
}
  .btn-toggle2 {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #ffffff1c;
    color: #fff;
    text-align: center;
    border-radius: 50%; 
    margin: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px; 
  
    &:hover {
      color: $highlight-color;
      transform: rotate(45deg);
      // animation: swing ease-in-out 0.5s 1 alternate;
      background:#5098ef;
      color:#000000;
    }

     
    &:before {
      color:red;
    }
  }

  
  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
         
      }
    }
  }
 

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column; 
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
           
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
       }
  }
  
  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }
  .dashboard-layout{
    
    height: 100%;
    padding: 0%;
    margin: 0%;
  }
  .dashboard-list-box{
    list-style-type: none;
    display: flex;
    align-items: center;
    
    height: 100%; 
    
  }

  @media (max-width: 767.98px) {
   
    header {
      h1 {
        font-size: 24px;
      }
    }
    .MuiGrid-grid-xs-6{
      flex-basis: 100%;
      max-width: 100%;
    }
    .layout_dashboard{
      margin-top: 50px;
    }
    .makeStyles-root-27{
      margin-top: 95px !important;
    }
    .makeStyles-layoutType-8{
      min-width: 100% !important;
      flex-basis: 100% !important;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
}
  }
  
  @media (min-width: 767.98px) {
     
    
    
    header {
      h1 {
        font-size: 24px;
      }
    }
    .pro-sidebar{
        min-width: 220px !important;
        width: 220px !important;
      //  clear: min-width !important;
    }
    
    .pro-icon-wrapper{
  
      width: 33px;
      height: 33px;
    }
    .pro-icon{
      font-size: 16px !important;
    }
    .fontSizeCellDiposit{
      font-size: 14px !important;
    }
    .fontSizeCellDiposit2{
      font-size: 13px !important;
    }
    .titleTable{
      font-size: 16px;
    }
  .layoutDraw{
    margin-right: 30px;
  //  margin-left: 30px;
    & .pro-sidebar-inner{
       background-color: #141319;
     // background-color: red;
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
      max-width: 280px !important;
      ::-webkit-scrollbar {
        width: 0px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track { 
        background: transparent;
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background:#424242;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
       
}
    margin-top: 30px; 
    max-height: 90%;
    
    

}
  }
  
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}
.tableDialog{
  //  width: 100%;
  align-items: center;  
    direction: rtl; 
    justify-content: center; 
    font-family: "title2";
    width: 100%;
  }
  .tableDialog_title{
  
    font-size: 13px;
    color:#a4a4a4;
    text-align: right; 
   min-width: 80px;
    padding-bottom: 15px;

     
    }
    .tableDialog_value{ 
      font-size: 14px;
      color:#fff;
      padding-bottom: 15px;
      direction: ltr;
      text-align: right; 
       
      }

.tableChildRow{
//  width: 100%;
align-items: center;
margin-right: 15px;
padding-right: 20px;
  padding-top: 10px;
  font-family: 'IRANSans' ; 
  font-weight: normal;
  font-size: 13px;
  td {
    color:white;
text-align: right;
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px; 
  }

   td:first-child{
    color:gray; 
    font-size: 12px;
  }
}
.nYegl{
  display: 
  flex;
  align-content: center;
  
  //top: 50%;
   label{
    color:#2e2e2e;
    margin-top: 5px;
    align-items: center; 
  justify-content: center;
  }
  
}
.dp__input{  
  background-color: #fff; 
  color:#2e2e2e;
  padding:5px;
  border:1px solid #f0f0f0; 
  border-radius:5px;
  width: 150px;
  font-size: 14px;
  margin-right: 5px;

}



@media (min-width: 858px) {
  [aria-label="collapsible table"] {
    overflow: hidden;
    }
  }

// screen full hd
@media (min-width: 1687px) {
  
  .titleDashboard{
    font-size: 18px;
  }
  .makeStyles-titleBoxDashboard-19{
    font-size: 14px;
  }
   .pro-sidebar {
    min-width: 250px !important;
    width: 250px !important;
}
.pro-item-content{
  font-size: 16px !important;
}
.makeStyles-root-55{
  font-size: 13px;
}
.tableChildRow{
  font-size: 13px;
}
.makeStyles-root-46 > *{
  font-size: 16px;
}


}