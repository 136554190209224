 .sc-kGXeez {
     display: inline;
     align-content: center;
     align-items: center;
 }

 .sc-kGXeez>div {
     display: inline-block;
     align-content: center;
     align-items: center;
 }

 .sc-kGXeez>label {
     color: gray;
     font-weight: normal;
     font-family: "IRANSans";
     font-size: 13px;
 }

 .jkcJde {
     margin: auto;
     margin-top: 50px;
 }

 .rdp__input--start {
     padding: 6px;
     margin-left: 30px;
     margin-right: 10px;
     width: 110px;
     border-radius: 3px;
     border-color: rgb(189, 189, 189) !important;
     border-width: 1px;
     border-style: solid;
     font-size: 14px;
 }

 .rdp__input--end {
     padding: 6px;
     margin-left: 10px;
     margin-right: 10px;
     width: 110px;
     border-radius: 3px;
     border-color: rgb(189, 189, 189) !important;
     border-width: 1px;
     border-style: solid;
     font-size: 14px;
 }