::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #2196f3;
  border-radius: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #2196f3;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #cfb53b40;
}
.scroller {
  overflow-y: scroll;
  scrollbar-color: #007bff30 #C2D2E4;
  width: 5px;
  height: 5px;
}
 
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 1px solid #5a5a5a;
  background: #141319;
  background-image: none;
  border-radius: 5px;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  line-height: 3;
  background: #2c3e50;
  overflow: hidden;
  border-radius: .25em;
}
.select:focus-within {
 
 outline: 0; 
 box-shadow: inset 0 0px 2px#fff, 0 0 4px #fff5;


}
.selectRed { 
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  line-height: 3;
  background: #2c3e50;
  overflow: hidden;
  border-radius: .25em;
}
datalist{
  flex: 1;
  padding: 0.5em;
  color: #fff;
  cursor: pointer;
  font-size: 14px; 
  font-family: 'title2'; 
}
select {
  flex: 1;
  padding: 0 .5em;
  color: #fff;
  cursor: pointer;
  font-size: 14px; 
  font-family: 'title2'; 
}
/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 1em;
  background: rgba(0, 123, 255, 0.5);
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
/* Transition */
.select:hover::after {
  color: #007bff ;
}

.selectRed::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 1em;
  background: #d32f2f90;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
/* Transition */
.selectRed:hover::after {
  color: #d32f2f ;
}


.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 1500ms ease-in;
  -webkit-transition: opacity 1500ms ease-in;
  -moz-transition: opacity 1500ms ease-in;
  -ms-transition: opacity 1500ms ease-in;
  -o-transition: opacity 1500ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  -ms-transition: opacity 400ms ease-in;
  -o-transition: opacity 400ms ease-in;
}
.loading_false {
 visibility: hidden;
}
.loading_true {
  animation: 650ms fadeIn;
  animation-fill-mode: backwards;
  -webkit-animation: 650ms fadeIn;
}

 
.status3 {
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color: #FFC400;
  background-color: #FFC40020;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #2196f3;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.status3_ {
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color: #000;
  background-color: #FFC400;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.status1 {
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color: #4CAF50;
  background-color: #4CAF5020;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.status1_ {
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color: #000;
  background-color: #4CAF50;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.status0 {
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  /* color: #ff6f00; */
  color:#76FF03;
  background-color: #76FF0320;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 10px;
}

.status2 {
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color: #d32f2f;
  background-color: #d5000010;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.status2_ {
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color: #000;
  background-color: #d32f2f;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
#tv {
  position: relative; 
  height: 50px; 
  background: #141319;
  border-radius: 8px;
  color: white; 
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-bottom: 2px solid #2196F350;
  padding-bottom: 7px;
  padding-top: 6px;
  font-family: "title3";
}

 

#pointer {
  width: 200px;
  height: 40px;
  position: relative;
  background: #141319;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

#pointer:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 20px solid #212227;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

#pointer:before {
  content: "";
  position: absolute;
  left: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 20px solid #141319;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #007bff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    /* visibility: visible; */
    opacity: 1;
  }
}


@keyframes leaves {
  0% {
    transform: scale(2.0);
    -webkit-transform: scale(2.0);
    -moz-transform: scale(2.0);
    -ms-transform: scale(2.0);
    -o-transform: scale(2.0);
  }
  100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    -moz-transform: scale(1.0);
    -ms-transform: scale(1.0);
    -o-transform: scale(1.0);
  }
}


@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
 